import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";

export default function FunFacts() {
  return (
    <section id="section-fun-facts" className="pt40 pb40 text-light bg-color-3">
      <div className="container">
        <div className="row">
          <FunFactCounter end={15425} delay={1} label="Website Powered" />
          <FunFactCounter end={8745} delay={1} label="Clients Supported" />
          <FunFactCounter end={235} delay={1} label="Awards Winning" />
          <FunFactCounter end={15} delay={1} label="Years Experience" />
        </div>
      </div>
    </section>
  );
}

function FunFactCounter({ end, delay, label }) {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = React.useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <div className="col-md-3 col-sm-6">
      <div className="de_count">
        <h3 className="timer">
          <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
            <CountUp end={isVisible ? end : 0} delay={delay} />
          </VisibilitySensor>
        </h3>
        <span>{t(label)}</span>
      </div>
    </div>
  );
}
