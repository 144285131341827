import { useState } from "react";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

import * as authService from "../../services/AuthService";
import BtnLoader from "../../components/Utils/BtnLoader";

import { Helmet } from "react-helmet-async";

export default function Register() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    domain_name: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setUserData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const validate = (e) => {
    switch (e.target.name) {
      case "name":
        setErrors((state) => ({
          ...state,
          name: userData.name.length < 1,
        }));

        break;
      case "phone":
        setErrors((state) => ({
          ...state,
          phone: userData.phone.length < 8,
        }));

        break;
      case "email":
        setErrors((state) => ({
          ...state,
          [e.target.name]: !userData.email.match(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        }));
        break;
      case "password":
        setErrors((state) => ({
          ...state,
          password: userData.password.length < 8,
        }));
        break;
      case "password_confirmation":
        setErrors((state) => ({
          ...state,
          password_confirmation:
            userData.password !== userData.password_confirmation,
        }));
        break;

      default:
        break;
    }
  };
  const submit = (e) => {
    e.preventDefault();
    if (
      errors.email ||
      errors.password ||
      errors.password_confirmation ||
      errors.password
    ) {
      return;
    }
    setLoading(true);
    authService
      .register(userData)
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          navigate("/login");
        } else {
          if (result.errors) {
            return setErrors(result.errors);
          }
          toast.error(result.message);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Helmet>
        <title>{t("Register")}</title>
        <meta
          name="description"
          content={t(
            "Register now! Join our community and unlock exciting benefits. Start your journey with us today."
          )}
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="no-bottom">
        <section id="subheader">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col-md-12 text-center">
                      <h1>{t("Register")}</h1>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="no-top">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h3>{t("Don't have an account? Register now.")}</h3>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p>
                <div className="spacer-10" />
                <form
                  name="registerForm"
                  id="register_form"
                  className="form-border"
                  method="post"
                  onSubmit={submit}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="field-set">
                        <label>{t("Name")}</label>
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${errors.name && "invalid"}`}
                          onChange={handleChange}
                          onBlur={validate}
                        />
                        {errors.name && (
                          <span className="error">{t("Name is required")}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field-set">
                        <label>{t("Email Address")}</label>
                        <input
                          type="text"
                          name="email"
                          className={`form-control ${
                            errors.email && "invalid"
                          }`}
                          onChange={handleChange}
                          onBlur={validate}
                        />
                        {errors.email && (
                          <span className="error">
                            {t("Invalid email address")}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="field-set">
                        <label>{t("Phone")}</label>
                        <input
                          type="text"
                          name="phone"
                          className={`form-control ${
                            errors.phone && "invalid"
                          }`}
                          onChange={handleChange}
                          onBlur={validate}
                        />
                        {errors.email && (
                          <span className="error">
                            {t("Invalid email address")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field-set">
                        <label>{t("Password")}</label>
                        <input
                          type="password"
                          name="password"
                          className={`form-control ${
                            errors.password && "invalid"
                          }`}
                          onChange={handleChange}
                          onBlur={validate}
                        />
                        {errors.password && (
                          <span className="error">
                            {t("Password is required")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field-set">
                        <label>{t("Re-enter Password")}</label>
                        <input
                          type="password"
                          name="password_confirmation"
                          className={`form-control ${
                            errors.password_confirmation && "invalid"
                          }`}
                          onChange={handleChange}
                          onBlur={validate}
                        />
                        {errors.password_confirmation && (
                          <span className="error">
                            {t("Passwords do not match")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div id="submit" className="pull-right">
                        <button
                          className=" btn-custom color-2"
                          disabled={loading}
                        >
                          {loading ? <BtnLoader /> : t("Register Now")}
                        </button>
                        <Link to="/login" className=" btn-primary">
                          {t("Sing in?")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
