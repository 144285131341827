import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../components/Utils/Loader";
import * as baseService from "../../services/BaseService";
import LanguageContext from "../../contexts/LanguageContext";
import Parser from "html-react-parser";

export default function InfoPage() {
  const { t } = useTranslation();
  const {slug} = useParams();
  const {language} = useContext(LanguageContext)
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  useEffect(() => {
    setLoading(true);
    baseService.getSinglePage(slug).then((result) => {
      if (result.success) {
        setPage(result.page);
      }
    }).catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setLoading(false);
    });
  }, [slug]);
  if (loading) {
    return <Loader />;
  }
    return (
      <div className="no-bottom">
        <section id="subheader">
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form className="row" method="post">
                    <div className="col-md-12 text-center">
                      <h1>{t(`${page.title?.[language]}`)}</h1>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container mb-5">
        {Parser(page.content?.[language])}
        </div>
      </div>
    );
}
