import { useContext } from "react";

import { NavLink } from "react-router-dom";
import enIcon from "../../assets/eng-icon.png";
import bgIcon from "../../assets/bg-icon.png";
import LanguageContext from "../../contexts/LanguageContext";

export default function LangSwitch() {
  const { language, setLanguage } = useContext(LanguageContext);
  function handleLanguageChange(e) {
    if (language === "bg") {
      setLanguage("en");
    } else {
      setLanguage("bg");
    }
  }
  return (
    <div className="lang_cont">
      <ul>
        <li
          className="has-children"
          onClick={(e) => handleLanguageChange(e, "en")}
        >
          <NavLink to="#">
            {language === "bg" && <img src={enIcon} alt="EN" />}
            {language === "en" && <img src={bgIcon} alt="BG" />}
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
