import { useEffect, useState } from "react";
import "./Profile.css";
import AccountInfo from "../../components/Profile/AccountInfo";
import { useTranslation } from "react-i18next";
import PaymentsInfo from "../../components/Profile/PaymentsInfo";
import { TabView, TabPanel } from "primereact/tabview";
import * as authServices from "../../services/AuthService";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../../components/Utils/Loader";
import Subscriptions from "../../components/Profile/Subscriptions";

import { Helmet } from "react-helmet-async";

export default function Profile() {
  const { t } = useTranslation();
  const { userData,token,logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [activeTab, setActiveTab] = useState("account-info");

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    setLoading(true);
    authServices
      .getProfileData(userData.id, token)
      .then((result) => {
        if (result.success) {
          setProfileData(result.data);
        }else{
            logout();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(setLoading(false));
  }, []);

  if (loading || !profileData) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{t("Profile")}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section id="subheader-profile">
        <div className="center-y relative text-center" data-scroll-speed={4}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <form className="row" method="post">
                  <div className="col-md-12 text-center">
                    <h1>{t("Profile")}</h1>
                  </div>
                  <div className="clearfix" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="profile-container container">
        <TabView>
          <TabPanel header={t("Account")}>
            <AccountInfo />
          </TabPanel>
          <TabPanel header={t("Payments")}>
            <PaymentsInfo
              purchases={profileData.purchases}
              projects={profileData.projects}
            />
          </TabPanel>
          <TabPanel header={t("Subscriptions")}>
            <Subscriptions projects={profileData.projects} />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
