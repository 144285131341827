import { useState } from "react";

export default function Step1({ packages, handleSubscriptionData }) {
  const preferred_plan_id = localStorage.getItem("preferred_plan_id");
  const [selectedPackage, setSelectedPackage] = useState(
    preferred_plan_id ? preferred_plan_id : packages[0].id
  );

  const handlePackageSelection = (packageId) => {
    setSelectedPackage(packageId);
    handleSubscriptionData("subscription_type_id", packageId);
    localStorage.setItem("preferred_plan_id", packageId);
  };

  return (
    <div className="c-selection selection-gap row justify-content-center pt-0 p-4 cards-wrapper col-md-9">
      {packages.map((item) => (
        <div
          key={item.id}
          className={`c-selection-card ${
            selectedPackage == item.id ? "selected" : ""
          }`}
          onClick={() => handlePackageSelection(item.id)}
        >
          <div className="c-selection-card__header">
            <div className="flex-container">
              <div className="flex-check">
                <input
                  id={`selection-${item.id}`}
                  name="selection"
                  className="c-selection-card__selector"
                  type="radio"
                  value={item.id}
                  checked={selectedPackage == item.id}
                  onChange={() => {}}
                />
                <label htmlFor={`selection-${item.id}`}>
                  <div className="control__indicator" />
                </label>
              </div>
              <div className="center">
                <div className="c-selection-card__heading">{item.name}</div>
              </div>
              <div className="flex-title" />
            </div>
          </div>
          <div className="c-selection-card__body">
            <div className="c-selection-card__price">
              {item.price}
              <span className="c-selection-card__currency">лв.</span>
            </div>
            <div className="c-selection-card__frequency">{item.duration}</div>
          </div>
          <div className="c-selection-card__footer">
            <div className="footer-item-left">(Save $180)</div>
            <div className="footer-item-right">*30-day guarantee</div>
          </div>
        </div>
      ))}
    </div>
  );
}
