import "./PricingPlans.css";
import FunFacts from "../../components/FAQ/FunFacts";
import CallSection from "../../components/Home/CallSection";
import PricingTable from "../../components/PricingTable/PricingTable";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function PricingPlans() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("Pricing Plans")}</title>
        <meta
          name="description"
          content={t(
            "Explore our Pricing Plans! Find the perfect option for your needs. Discover competitive rates and features."
          )}
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="pricing-container">
        <section
          id="subheader"
          data-bgimage="url(images/background/5.png) bottom"
        >
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col-md-12 text-center">
                      <h1>{t("Pricing Plans")}</h1>
                      <p>Awsome Page Teaser Here</p>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PricingTable />
        <FunFacts />
      </div>
    </>
  );
}
