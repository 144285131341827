import React from "react";
import { useWizard } from "react-use-wizard";

const WizardHeader = ({ steps }) => {
  const { activeStep } = useWizard();
  return (
    // <div className="wizard-header">
    //   <div className="step-indicator">
    //     {steps.map((step, index) => (
    //       <span key={index} className={index === activeStep ? "active" : ""}>
    //         {step.name}
    //       </span>
    //     ))}
    //   </div>
    // </div>
    <div className="row justify-content-center pt-0 p-4" id="wizardRow">
      <div className="col-md-10 text-center">
        <div className="wizard-form py-4 my-2">
          {/* ul */}
          <ul id="progressBar" className="progressbar px-lg-5 px-0">
            {steps.map((step, index) => (
              <li
                id={`progressList-${index + 1}`}
                className={`d-inline-block fw-bold w-25 position-relative text-center float-start progressbar-list ${activeStep >= index  ? "active" : ""}`}
                key={index}
              >
                {step.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WizardHeader;
