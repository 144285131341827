import { useEffect, useState } from "react";
import "./PricingTable.css";
import SubscriptionCard from "./SubscriptionCard";

import * as subscriptionService from "../../services/SubscriptionService";
import Loader from "../Utils/Loader";
export default function PricingTable() {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    subscriptionService
      .getSubscriptionPackages()
      .then((result) => {
        setPackages(result.packages);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div id="table-container">
        <section className="pricing-no-top" style={{ background: "none" }}>
          <div className="container">
            <div className="item pricing">
              <div className="container">
                <div className="row">
                  {packages.map((item, index) => (
                    <SubscriptionCard
                      key={index}
                      item={item}
                      color={`bg-color-${(index % 3) + 1}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
