import React, { createContext, useContext, useState } from "react";
import * as authService from "../services/AuthService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("user_ddd"));
  const token = JSON.parse(localStorage.getItem("ddd_token"));
  const [isAuth, setIsAuth] = useState(
    userData ? (userData.id ? true : false) : false
  );

  const login = (user,token) => {
    setIsAuth(true);
    localStorage.setItem("user_ddd", JSON.stringify(user));
    localStorage.setItem("ddd_token", JSON.stringify(token));
  };

  const logout = () => {
    authService
      .logout()
      .then((result) => {
        if (result.success) {
          setIsAuth(false);
          localStorage.removeItem("user_ddd");
          localStorage.removeItem("ddd_token");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        login,
        logout,
        userData,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
