import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./pages/HomePage/HomePage";
import Footer from "./components/Footer/Footer";
import FAQ from "./pages/FAQ/FAQ";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import PricingPlans from "./pages/PricingPlans/PricingPlans";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Subscription from "./pages/Subscription/Subscription";
import Profile from "./pages/Profile/Profile";
import PrivateRoutes from "./components/Utils/PrivateRoutes";
import ErrorPage from "./pages/ErrorPage";
import InfoPage from "./pages/InfoPage/InfoPage";
import { useEffect } from "react";
import { useAuth } from "./contexts/AuthContext";

import * as authServices from "./services/AuthService";
function App() {
  const { userData, logout, token } = useAuth();
  useEffect(() => {
    if (userData && userData.id) {
      authServices
        .checkToken(userData.id, token)
        .then((result) => {
          if (!result.success) {
            logout();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing-plans" element={<PricingPlans />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/info/:slug" element={<InfoPage />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/subscribe" element={<Subscription />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
