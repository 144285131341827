import "./NavBar.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import LangSwitch from "./LangSwitch";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";

import * as baseService from "../../services/BaseService";
import LanguageContext from "../../contexts/LanguageContext";

export default function NavBar() {
  const { isAuth, logout } = useAuth();
  const { pathname } = useLocation();
  const { language } = useContext(LanguageContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const [pages, setPages] = useState(null);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [menuHeight, setMenuHeight] = useState(90);
  const [activeMenuItem, setActiveMenuItem] = useState("/");
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const contentRef = useRef(null);
  useEffect(() => {
    baseService
      .getPages()
      .then((result) => {
        if (result.success) {
          setPages(result.pages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 100);
    };

    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setActiveMenuItem(pathname);
  }, [pathname]);
  useEffect(() => {
    if (contentRef.current) {
      setMenuHeight(contentRef.current.clientHeight);
    }
  }, [contentRef]);
  return (
    <div id="wrapper">
      {/* header begin */}
      <header
        className={`header-light transparent scroll-light ${
          isScrolled ? "smaller" : ""
        } ${screenSize < 1000 ? "header-mobile" : ""}`}
        style={{
          height:
            screenSize < 1000 && showMenu ? `${menuHeight + 120}px` : "90px",
        }}
      >
        <div className="custom-container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <div className="align-self-center header-col-left">
                  {/* logo begin */}
                  <div id="logo">
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        isActive ? "nav-active" : ""
                      }
                    >
                      {/* 
                      <img alt="" src="images/logo-2.png" />
                      */}
                    </NavLink>
                  </div>
                  {/* logo close */}
                </div>
                <div className="align-self-center ml-auto header-col-mid">
                  {/* mainmenu begin */}
                  <ul id="mainmenu" ref={contentRef}>
                    <li className={activeMenuItem == "/" ? "nav-active" : ""}>
                      <NavLink to="/" className="a">
                        {t("Home")}
                      </NavLink>
                    </li>
                    <li
                      className={
                        activeMenuItem == "/pricing-plans" ? "nav-active" : ""
                      }
                    >
                      <NavLink to="/pricing-plans" className="a">
                        {t("Pricing Plans")}
                      </NavLink>
                    </li>

                    <li
                      className={
                        activeMenuItem == "/contact-us" ? "nav-active" : ""
                      }
                    >
                      <NavLink to="/contact-us" className="a">
                        {t("Contact Us")}
                      </NavLink>
                    </li>

                    {screenSize < 600 && (
                      <>
                        <li
                          className={
                            activeMenuItem == "/login" ? "nav-active" : ""
                          }
                        >
                          <NavLink to="/login" className="a">
                            {t("Sign in")}
                          </NavLink>
                        </li>
                      </>
                    )}
                    {screenSize > 1000 ? (
                      <li className={""}>
                        <NavLink
                          to="/#"
                          className="a"
                          onClick={(e) => e.preventDefault()}
                        >
                          {t("Info")}
                        </NavLink>
                        <ul>
                          <li
                            className={
                              activeMenuItem == "/faq" ? "nav-active" : ""
                            }
                          >
                            <NavLink to="/faq" className="a">
                              {t("FAQs")}
                            </NavLink>
                          </li>
                          <li
                            className={
                              activeMenuItem == "/about" ? "nav-active" : ""
                            }
                          >
                            <NavLink to="/about" className="a">
                              {t("About Us")}
                            </NavLink>
                          </li>
                          {pages &&
                            pages.map((page) => (
                              <li key={page.id}>
                                <NavLink
                                  to={`/info/${page.slug}`}
                                  className="a"
                                >
                                  {page.title?.[language]}
                                </NavLink>
                              </li>
                            ))}
                        </ul>
                      </li>
                    ) : (
                      pages &&
                      pages.map((page) => (
                        <li key={page.id}>
                          <NavLink to={`/info/${page.slug}`} className="a">
                            {page.title?.[language]}
                          </NavLink>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
                <div className="align-self-center ml-auto header-col-right action-btns">
                  {isAuth ? (
                    <>
                      <NavLink className="btn-custom sign-btn" to="/profile">
                        <i className="fa-solid fa-user"></i> {t("Account")}
                      </NavLink>
                    </>
                  ) : (
                    <NavLink
                      to="/login"
                      className="btn-custom sign-btn"
                      href="#"
                    >
                      <i className="fa-solid fa-right-to-bracket"></i>{" "}
                      {t("Sign in")}
                    </NavLink>
                  )}

                  <Link
                    className="btn-custom"
                    to={`${isAuth ? "/subscribe" : "/login"}`}
                  >
                    <i className="fa fa-arrow-down" /> {t("Buy Now")}
                  </Link>
                  {isAuth && (
                    <NavLink
                      className="btn-custom btn-logout"
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        logout();
                      }}
                    >
                      <i className="fa-solid fa-right-to-bracket"></i>
                    </NavLink>
                  )}
                  <LangSwitch />
                  <span
                    id="menu-btn"
                    onClick={() => setShowMenu((state) => !state)}
                  />
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
