import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const PrivateRoutes = () => {
  const { isAuth } = useAuth();
  const location = useLocation();

  const privateRoutes = ["/subscribe", "/profile"];

  const isPrivateRoute = privateRoutes.includes(location.pathname);

  if (isAuth && !isPrivateRoute) {
    return <Navigate to="/profile" replace />;
  } else if (!isAuth && isPrivateRoute) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
