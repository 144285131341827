import { useTranslation } from "react-i18next";
import AccordionItem from "../../components/FAQ/AcordionItem";
import FunFacts from "../../components/FAQ/FunFacts";
import CallSection from "../../components/Home/CallSection";
import "./FAQ.css";
import { Helmet } from "react-helmet-async";

export default function FAQ() {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("FAQs")}</title>
        <meta
          name="description"
          content={t(
            "Explore our FAQs for quick answers! Learn more about our services. We're here to help you better understand our offerings. Get in touch for further assistance."
          )}
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="faq-container">
        {/* Header section  */}
        <section id="subheader">
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col-md-12 text-center">
                      <h1>{t("FAQs")}</h1>
                      <p>Awsome Page Teaser Here</p>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Acordion section  */}
        <section className="no-top relative pos-top">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div id="accordion-1" className="accordion accordion-style-1">
                  <AccordionItem
                    header="Accordion Item 1"
                    children="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                  terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
                  skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                  Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                  single-origin coffee nulla assumenda shoreditch et."
                  />
                  <AccordionItem
                    header="Accordion Item 2"
                    children="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                  terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
                  skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                  Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                  single-origin coffee nulla assumenda shoreditch et."
                  />
                  <AccordionItem
                    header="Accordion Item 3"
                    children="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                  terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
                  skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                  Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                  single-origin coffee nulla assumenda shoreditch et."
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div id="accordion-1" className="accordion accordion-style-1">
                  <AccordionItem
                    header="Accordion Item 4"
                    children="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                  terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
                  skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                  Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
                  single-origin coffee nulla assumenda shoreditch et."
                  />
                  <AccordionItem
                    header="Accordion Item 5"
                    children="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
          skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et."
                  />
                  <AccordionItem
                    header="Accordion Item 6"
                    children="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
                  terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
          skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et."
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* CallToActionSection */}
        <CallSection />
        {/* Fun Facts  */}
        <FunFacts />
      </div>
    </>
  );
}
