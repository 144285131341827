import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./ContactUs.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import * as baseService from "../../services/BaseService";

export default function ContactUs() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const validate = (e) => {
    switch (e.target.name) {
      case "name":
        setErrors((state) => ({
          ...state,
          name: formData.name.length < 1,
        }));
        break;
      case "email":
        setErrors((state) => ({
          ...state,
          [e.target.name]: !formData.email.match(
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        }));
        break;
      case "phone":
        setErrors((state) => ({
          ...state,
          phone: formData.phone.length < 8,
        }));
        break;
      case "message":
        setErrors((state) => ({
          ...state,
          message: formData.message.length < 1,
        }));
        break;

      default:
        break;
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (errors.name || errors.email || errors.phone || errors.message) {
      return;
    }


    // Call the sendContact function to actually send the form data
    baseService
      .sendContact(formData)
      .then((response) => {
        console.log("Contact form submitted successfully:", response);
        // Optionally, you can reset the form data after successful submission
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      })
      .catch((err) => {
        console.error("Error submitting contact form:", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>{t("Contact Us")}</title>
        <meta
          name="description"
          content={t(
            "Welcome to our 'Contact Us' page! We value your feedback and inquiries. Whether you have questions about our products, services, we're here to help."
          )}
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="no-bottom no-top" id="content">
        <div id="top" />
        <section id="subheader">
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form className="row" method="post">
                    <div className="col-md-12 text-center">
                      <h1>{t("Contact Us")}</h1>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="no-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-md-2 mb-sm-30">
                <h3 className="contact-h3">{t("Do you have any question?")}</h3>
                <form
                  className="form-border"
                  id="contact-form"
                  method="post"
                  onSubmit={submit}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("Your Name")}
                      name={"name"}
                      className={`form-control ${errors.name && "invalid"}`}
                      onChange={handleChange}
                      onBlur={validate}
                    />
                    {errors.name && (
                      <span className="error">{t("Name is required")}</span>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("Your Email")}
                      name={"email"}
                      className={`form-control ${errors.email && "invalid"}`}
                      onChange={handleChange}
                      onBlur={validate}
                    />
                    {errors.email && (
                      <span className="error">
                        {t("Invalid email address")}
                      </span>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={t("Your Phone")}
                      name={"phone"}
                      className={`form-control ${errors.phone && "invalid"}`}
                      onChange={handleChange}
                      onBlur={validate}
                      style={{ background: "none" }}
                    />
                    {errors.phone && (
                      <span className="error">
                        {t("Please add a phone number")}
                      </span>
                    )}
                  </InputGroup>
                  <div className="field-set">
                    <textarea
                      name="message"
                      onChange={handleChange}
                      className={`form-control ${errors.message && "invalid"}`}
                      placeholder={t("Your Message")}
                      defaultValue={""}
                    />
                    {errors.message && (
                      <span className="error">{t("Please add a message")}</span>
                    )}
                  </div>
                  <div className="spacer-half" />
                  <Button
                    type="submit"
                    className="submit-form"
                    id="submit"
                    style={{
                      backgroundColor: "#5B84F8",
                      borderRadius: "30px",
                      padding: "12px 30px",
                      minWidth: "120px",
                      outline: "0",
                      fontWeight: "bold",
                      textDecoration: "none",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                  >
                    {t("Submit Message")}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
