const baseUrl = process.env.REACT_APP_API_URL;


export const getSubscriptionPackages = async () => {
  try {
    const response = await fetch(`${baseUrl}/api/packages`);
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};
export const createSubscription = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/api/stripe/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
  } catch (err) {
    throw new Error({ message: err.message });
  }
};
export const checkDomain = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/api/check-domain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
  } catch (err) {
    throw new Error({ message: err.message });
  }
};