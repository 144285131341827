import "./AboutUs.css";
import workPersonImg from "../../assets/1.png";
import workPersonImg2 from "../../assets/5.png";
import dddAbout from "../../assets/dddAbout.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function AboutUs() {
  const { t } = useTranslation();
   
  return (
    <>
      <Helmet>
        <title>{t("About Us")}</title>
        <meta name="description" content="We are bla bla bla" data-rh="true" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="about-us-section">
        {/* Header section  */}
        <section
          id="subheader"
          data-bgimage="url(images/background/5.png) bottom"
        >
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col text-center">
                      <div className="spacer-single" />
                      <h1>{t("About Us")}</h1>
                      <p>Awsome Page Teaser Here</p>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* content section  */}

        <section className="no-top content-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 ">
                <img src={dddAbout} className="img-fluid" alt="" />
              </div>
              <div className="col-md-6 offset-md-1">
                <h3>{t("What makes us different?")}</h3>
                <p>
                  {t(
                    "At dddsoft.eu, we're not just another pest cleaner subscription service, we're revolutionizing the way business owners manage their pest control needs. Here's what sets us apart:"
                  )}
                </p>
                <p>
                  <span className="description_title">
                    {t("Tailored Solutions: ")}
                  </span>
                  {t(
                    "We understand that every business has unique requirements when it comes to pest control. That's why we offer personalized subscription plans tailored to your specific needs, ensuring you get the most effective and efficient service possible."
                  )}
                </p>
                <p>
                  <span className="description_title">
                    {t("Seamless Management: ")}
                  </span>
                  {t(
                    "Say goodbye to the hassle of managing multiple vendors and invoices. Our intuitive platform allows business owners to allocate tasks, track payments, monitor pending processes, and even manage their fleet of vehicles, all in one place."
                  )}
                </p>
                <p>
                  <span className="description_title">
                    {t("Cutting-Edge Technology: ")}
                  </span>
                  {t(
                    "Leveraging the latest advancements in pest control technology, we deliver superior results with minimal disruption to your operations. From eco-friendly solutions to state-of-the-art equipment, we're committed to staying ahead of the curve."
                  )}
                </p>
                <p>
                  <span className="description_title">
                    {t("Focus on Sustainability: ")}
                  </span>
                  {t(
                    "By streamlining tasks, optimizing routes, and minimizing unnecessary resource consumption, our software helps businesses operate more efficiently."
                  )}
                </p>
                <p>
                  <span className="description_title">
                    {t("Paperless Operations: ")}
                  </span>
                  {t(
                    "Our platform enables businesses to digitize their processes, eliminating the need for paper-based documentation and reducing waste. From invoices to reports, everything can be managed electronically, contributing to a greener future."
                  )}
                </p>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-6">
                <h3>{t("Our Commitment to Excellence")}</h3>
                <p>
                  {t(
                    "At dddsoft.eu, we are driven by a relentless pursuit of excellence in everything we do. Our mission goes beyond providing a mere service; it's about empowering businesses to thrive. Through our innovative platform and unwavering dedication to customer satisfaction, we aim to be more than just a vendor—we strive to be a trusted partner in your success journey."
                  )}
                </p>
                <p>
                  {t(
                    "With a team of industry experts and software engineers, we are committed to staying ahead of the curve, anticipating your needs, and delivering solutions that exceed expectations. We understand the challenges businesses face, and we are continuously refining our platform to address them effectively."
                  )}
                </p>
                <p>
                  {t(
                    "By choosing dddsoft.wu, you're not just investing in a subscription service; you're investing in peace of mind. You can trust us to handle your pest control needs efficiently and effectively, allowing you to focus on what truly matters—growing your business and achieving your goals."
                  )}
                </p>
                <p>
                  {t(
                    "Join us in our mission to redefine the standard of excellence in the industry. Experience the difference that our innovative platform and dedicated support can make for your business. Together, let's embark on a journey towards success."
                  )}
                </p>
              </div>
              <div className="col-md-5  offset-md-1">
                <img src={workPersonImg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="pt60 pb60 bg-color-3 text-light p-60">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8 mb-sm-30 text-lg-left text-sm-center">
                <h3 className="no-bottom">
                  {t("Awesomeness begin here. Are you ready?")}
                </h3>
              </div>
              <div className="col-md-4 text-lg-right text-sm-center">
                <a href="#" className="btn-custom capsule med">
                  {t("Let's do it!")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
