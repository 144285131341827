import { useState, useRef, useEffect } from "react";

export default function AccordionItem({ header, children }) {
  const [collapsed, setCollapsed] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!collapsed) {
      // Expand the content
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      // Collapse the content
      contentRef.current.style.maxHeight = "0";
    }
  }, [collapsed]);

  return (
    <div className="card" onClick={() => setCollapsed((state) => !state)}>
      <div id="heading-a1" className="card-header bg-white shadow-sm border-0">
        <h6 className="mb-0 font-weight-bold">
          <a
            href="#"
            aria-expanded={collapsed ? "false" : "true"}
            aria-controls="collapse-a1"
            className="d-block position-relative text-dark collapsible-link py-2"
          >
            {header}
          </a>
        </h6>
      </div>
      <div
        id="collapse-a1"
        aria-labelledby="heading-a1"
        data-parent="#accordion-1"
        className={`collapse ${!collapsed ? "show" : ""}`} // Toggle show class based on collapsed state
        ref={contentRef}
      >
        <div className="card-body p-4">
          <p>{children}</p>
        </div>
      </div>
    </div>
  );
}
