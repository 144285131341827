import React, { useState, useEffect } from "react";
import "./PaymentsInfo.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";
export default function PaymentsInfo({ purchases , projects}) {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [headers, setHeaders] = useState({
    package: t("Package"),
    duration: t("Duration"),
    price: t("Price"),
    domain: t("Domain"),
    paymentStatus: t("Payment status"),
    payment_date: t("Payment Date"),
  });
  useEffect(() => {
    // Update the headers when the language changes
    setHeaders({
      package: t("Package"),
      duration: t("Duration"),
      price: t("Price"),
      domain: t("Domain"),
      paymentStatus: t("Payment status"),
      payment_date: t("Payment Date"),
    });
  }, [t, language]);

  const priceBodyTemplate = (purchase) => {
    return `${purchase.order_data.subscription_type_price} лв.`;
  };
  const statusBodyTemplate = (purchase) => {
    const data = getSeverity(purchase);
    return <Tag value={data.status} severity={data.severity}></Tag>;
  };
  const domainBodyTemplate = (purchase) => {
    const project = projects?.find((project) => project.id === purchase.project_id);
    const domain = project ? `https://${project.domain_name}.dddsoft.eu` : null;
    return domain ? (
      <a
        href={domain}
        target="_blank"
        rel="noopener noreferrer"
        className="font-bold"
      >
        {domain}
      </a>
    ) : (
      "N/A"
    );
  };
  const getSeverity = (purchase) => {
    if (purchase.paid) {
      return {
        status: t("Paid"),
        severity: "success",
      };
    } else {
      return {
        status: t("Failed"),
        severity: "danger",
      };
    }
  };
  const formatDate = (purchase) => {
    const date = new Date(purchase.created_at);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">{t("Payments")}</span>
    </div>
  );
  const footer = `${t("In total there are ")} ${
    purchases ? purchases.length : 0
  } ${t("payments.")}`;

  return (
    <>
      {purchases.length === 0 ? (
        <div className="no-data">{t("No payments found.")}</div>
      ) : (
        <div className="payment-card">
          <DataTable
            value={purchases}
            header={header}
            footer={footer}
            tableStyle={{ minWidth: "60rem" }}
            paginator
            rows={10}
          >
            <Column
              field="order_data.subscription_type_name"
              header={headers.payment_date}
              body={formatDate}
            ></Column>
            <Column
              field="order_data.subscription_type_name"
              header={headers.package}
            ></Column>
            <Column
              field="order_data.subscription_type_duration"
              header={headers.duration}
            ></Column>
            <Column
              field="price"
              header={headers.price}
              body={priceBodyTemplate}
            ></Column>
            <Column
              field="order_data.client_domain"
              header={headers.domain}
              body={domainBodyTemplate}
            ></Column>
            <Column
              header={headers.paymentStatus}
              body={statusBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}
