import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


export default function ErrorPage() {
const { t } = useTranslation();
return (
  <section id="errorPage">
   
    <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
            <h1>{t("404")}</h1>
            <h2>{t("Page Not Found")}</h2>
            <p>{t("The page you are looking for might have been removed had its name changed or is temporarily unavailable.")}</p>
            <Link to="/" className="btn btn-primary">{t("Back to Home")}</Link>
            </div>
        </div>
    </div>
  </section>
);
}