import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";
import * as subscriptionService from "../../../services/SubscriptionService";
import InputLoader from "../../Utils/InputLoader";
export default function Step2({
  handleSubscriptionData,
  updateErrors,
  subscriptionData,
  main_domain,
}) {
  const { t } = useTranslation();
  const [checking, setChecking] = useState(true);
  const [formData, setFormData] = useState({
    project_email: subscriptionData?.project_email
      ? subscriptionData?.project_email
      : "",
    project_name: subscriptionData?.project_name
      ? subscriptionData?.project_name
      : "",
    domain_name: subscriptionData?.domain_name
      ? subscriptionData?.domain_name
      : "",
    project_password: subscriptionData?.project_password
      ? subscriptionData?.project_password
      : "",
    password_confirmation: subscriptionData?.password_confirmation
      ? subscriptionData?.password_confirmation
      : "",
  });
  const [showPassword, setShowPassword] = useState({
    project_password: false,
    password_confirmation: false,
  });
  const debouncedDomainName = useDebounce(formData.domain_name, 1000);
  const [localErrors, setLocalErrors] = useState({});
  useEffect(() => {
    // Set errors for empty inputs when component mounts
    const emptyErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        emptyErrors[key] = true;
      }
    }
    updateErrors(emptyErrors);
  }, []);
  useEffect(() => {
    if (debouncedDomainName) {
      checkDomain();
    }
  }, [debouncedDomainName]);
  const handleShowPassword = (name) => {
    setShowPassword((prevShow) => ({
      ...prevShow,
      [name]: !prevShow[name],
    }));
  };
  const validate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "project_email":
        const checkEmail = !value.match(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        );
        updateErrors({
          project_email: checkEmail,
        });
        setLocalErrors((prevErrors) => ({
          ...prevErrors,
          project_email: checkEmail,
        }));
        break;
      case "project_name":
        const checkPhone = value.length < 2;
        updateErrors({ project_name: checkPhone });
        setLocalErrors((prevErrors) => ({
          ...prevErrors,
          project_name: checkPhone,
        }));
        break;
      case "project_password":
        const checkPasswordLength = value.length < 8;
        setLocalErrors((state) => ({
          ...state,
          project_password: checkPasswordLength,
        }));
        updateErrors({ project_password: checkPasswordLength });
        break;
      case "password_confirmation":
        const checkPasswordsMatch = value !== formData.project_password;
        setLocalErrors((state) => ({
          ...state,
          password_confirmation: checkPasswordsMatch,
        }));
        updateErrors({ password_confirmation: checkPasswordsMatch });
        break;
      default:
        break;
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Trigger validation when input changes
    handleSubscriptionData(name, value);
  };
  const checkDomain = () => {
    const data = { domain_name: formData.domain_name };
    setChecking(true);
    subscriptionService
      .checkDomain(data)
      .then((result) => {
        if (result.success) {
          updateErrors({ domain_name: false });
          setLocalErrors((prevErrors) => ({
            ...prevErrors,
            domain_name: false,
          }));
        } else {
          updateErrors({ domain_name: true });
          setLocalErrors((prevErrors) => ({
            ...prevErrors,
            domain_name: true,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setChecking(false);
      });
  };

  return (
    <div className="container billing_cont">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <form className="form-border flex">
            <div className="row">
              <div className="col-md-6">
                <div className="field-set">
                  <label>{t("Email")}</label>
                  <input
                    type="text"
                    name="project_email"
                    className={`form-control ${
                      localErrors.project_email && "invalid"
                    }`}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={validate}
                    value={formData.project_email}
                  />
                  {localErrors.project_email && (
                    <span className="error">{t("Invalid email address")}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="field-set">
                  <label>{t("Project Name")}</label>
                  <input
                    type="text"
                    name="project_name"
                    className={`form-control ${
                      localErrors.project_name && "invalid"
                    }`}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={validate}
                    value={formData.project_name}
                  />
                  {localErrors.project_name && (
                    <span className="error">
                      {t("Project name is required")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="field-set password_cont">
                  <label>{t("Password")}</label>
                  <input
                    type={showPassword.project_password ? "text" : "password"}
                    name="project_password"
                    className={`form-control ${
                      localErrors.project_password && "invalid"
                    }`}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={validate}
                    value={formData.project_password}
                  />
                  {showPassword.project_password ? (
                    <i
                      className="fa-solid fa-eye-slash"
                      onClick={() => handleShowPassword("project_password")}
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-eye"
                      onClick={() => handleShowPassword("project_password")}
                    ></i>
                  )}
                  {localErrors.project_password && (
                    <span className="error">
                      {t("Password must be at least 8 characters long")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="field-set password_cont">
                  <label>{t("Re-enter Password")}</label>
                  <input
                    type={
                      showPassword.password_confirmation ? "text" : "password"
                    }
                    name="password_confirmation"
                    className={`form-control ${
                      localErrors.password_confirmation && "invalid"
                    }`}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={validate}
                    value={formData.password_confirmation}
                  />
                  {showPassword.password_confirmation ? (
                    <i
                      className="fa-solid fa-eye-slash"
                      onClick={() =>
                        handleShowPassword("password_confirmation")
                      }
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-eye"
                      onClick={() =>
                        handleShowPassword("password_confirmation")
                      }
                    ></i>
                  )}
                  {localErrors.password && (
                    <span className="error">
                      {t("Password must be at least 8 characters long")}
                    </span>
                  )}
                  {localErrors.password_confirmation && (
                    <span className="error">{t("Passwords do not match")}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="field-set">
                  <label>{t("Domain")}</label>
                  <div className="domain_input">
                    <span className="input-group-addon">https://</span>
                    <div className="input_holeder">
                      <input
                        type="text"
                        name="domain_name"
                        className={`form-control ${
                          localErrors.domain_name && "invalid"
                        }`}
                        placeholder=""
                        onChange={handleChange}
                        value={formData.domain_name}
                      />

                      {formData.domain_name.length > 0 && (
                        <>
                          {checking ? (
                            <InputLoader />
                          ) : localErrors.domain_name ? (
                            <i className="fa-solid fa-circle-exclamation"></i>
                          ) : (
                            <i className="fa-solid fa-circle-check"></i>
                          )}
                        </>
                      )}
                    </div>
                    <span className="input-group-addon">{main_domain}.eu</span>
                  </div>
                  {localErrors.domain_name && (
                    <span className="error">
                      {t("The domain is not available")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
