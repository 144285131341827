import { useTranslation } from "react-i18next";
import "./CallSection.css"
export default function CallSection() {
  const {t} = useTranslation();
  return (
    <section className="call-to-action call-to-action-1 pt-100 pb-100 bg-overlay bg-overlay-2 text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>
              <span className="id-color">{t("Call us")}</span>{" "}
              {t(
                "for further information.Bluetec customer care is here to help you"
              )}{" "}
              <span className="id-color">{t("anytime")}</span>.{" "}
            </h2>
            <p className="lead">{t("We're available for 24 hours!")}</p>
          </div>
          <div className="col-md-6 text-lg-center text-sm-center">
            <div className="phone-num-big">
              <i className="fa fa-phone id-color" />
              <span className="pnb-text">{t("Call Us Now")}</span>
              <span className="pnb-num">1 200 333 800</span>
            </div>
            <a href="#" className="btn-custom capsule med">
              {t("Contact Us")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
