const baseUrl = process.env.REACT_APP_API_URL;

export const login = async (user) => {
  try {
    const response = await fetch(`${baseUrl}/api/login-client`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
  } catch (err) {
    throw new Error({ message: err.message });
  }
};
export const register = async (user) => {
  try {
    const response = await fetch(`${baseUrl}/api/register-client`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
  } catch (err) {
    throw new Error({ message: err.message });
  }
};
export const logout = async () => {
  try {
    const response = await fetch(`${baseUrl}/api/logout-client`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    } 
  } catch (err) {
    throw new Error({ message: err.message });
  }
};
export const getProfileData = async ($id,token) => {
  try {
    const response = await fetch(`${baseUrl}/api/purchases-client/${$id}`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};
export const checkToken = async ($id, token) => {
  try {
    const response = await fetch(`${baseUrl}/api/check-client/${$id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};