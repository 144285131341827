import { Button } from "react-bootstrap";
import "./AccountInfo.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
export default function AccountInfo() {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const [user, setUser] = useState(userData);
  const handleUserChange = (e) => {
    setUser((state) => ({ ...state, [e.target.name]: e.target.value }));
  };
  return (
    <>
      <div className="col-xl-8">
        <div className="card-body">
          <form>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputFirstName">
                  {t("Name")}
                </label>
                <input
                  className="form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder={t("Please enter your name")}
                  name="name"
                  value={user?.name}
                  onChange={handleUserChange}
                />
              </div>
            </div>

            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputEmailAddress">
                  {t("Email address")}
                </label>
                <input
                  className="form-control"
                  id="inputEmailAddress"
                  type="email"
                  placeholder={t("Please enter your email address")}
                  name="email"
                  value={user?.email}
                  onChange={handleUserChange}
                />
              </div>
            </div>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputPhone">
                  {t("Phone number")}
                </label>
                <input
                  className="form-control"
                  id="inputPhone"
                  type="tel"
                  name="phone"
                  placeholder={t("Please enter your phone nubmer")}
                  value={user?.phone}
                  onChange={handleUserChange}
                />
              </div>
            </div>
            <button className="btn-custom" type="button">
              {t("Save changes")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
