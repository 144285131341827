import React from "react";
import './Footer.css';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const {t} = useTranslation();
    return (
      <footer className="footer footer-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="widget">
                <a onClick={(e)=>e.preventDefault()}>
                  {/*  <img alt="" className="logo" src="images/logo-2.png" />*/}
                </a>
                <div className="spacer-20" />
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.
                </p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="widget">
                <h5>{t("Quick navigation")}</h5>
                <ul>
                  <li>
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{t("FAQs")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{t("Contact Us")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="widget">
                <h5>.</h5>
                <ul>
                  <Link to="/about">{t("About Us")}</Link>
                  <li></li>
                  <li>
                    <Link to="/pricing-plans">{t("Pricing Plans")}</Link>
                  </li>
                  <li>
                    <Link to="/login">{t("Login")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="widget">
                <h5>{t("Newsletter")}</h5>
                <p>
                  {t(
                    "Signup for our newsletter to get the latest news, updates and special offers in your inbox."
                  )}
                </p>
                <form
                  action="blank.php"
                  className="row"
                  method="post"
                  name="form_subscribe"
                >
                  <div className="col text-center">
                    <input
                      className="form-control"
                      id="name_1"
                      name="name_1"
                      placeholder={t("enter your email")}
                      type="text"
                    />{" "}
                    <a onClick={(e) => e.preventDefault()} id="btn-submit">
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                    <div className="clearfix" />
                  </div>
                </form>
                <div className="spacer-10" />
                <small>{t("Your email is safe with us. We don't spam.")}</small>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 sm-text-center mb-sm-30">
              <div className="mt10">
                © Copyright 2023 - Bluetec by Designesia
              </div>
            </div>
            <div className="col-md-6 text-md-right text-sm-left">
              <div className="social-icons">
                <a onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-facebook fa-lg" />
                </a>
                <a onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-twitter fa-lg" />
                </a>
                <a onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-linkedin fa-lg" />
                </a>
                <a onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-google fa-lg" />
                </a>
                <a onClick={(e) => e.preventDefault()}>
                  <i className="fa fa-rss fa-lg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
}