import OurServices from "../../components/Home/OurServices";
import home from "../../assets/home.png";
import dddHome from "../../assets/dddHome.png";
import home2 from "../../assets/home2.png";
import "./HomePage.css";
import PricingTable from "../../components/PricingTable/PricingTable";
import CallSection from "../../components/Home/CallSection";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const {t} = useTranslation();
  return (
    <div className="no-bottom no-top" id="content">
      <div id="top" />
      <section id="section-hero" className="full-height vertical-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 wow fadeInRight" data-wow-delay=".5s">
              <h4 className="id-color">{t("We're SEO experts")}</h4>
              <div className="spacer-10" />
              <h1>Ready to launch your startup?</h1>
              <p className="lead">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt. Ut enim ad minim veniam.
              </p>
              <div className="spacer-20" />
              <a className="btn-custom" href="#">
                {t("Read More")}
              </a>
              &nbsp;
              {/* <a className="btn-custom bg-color-2" href="#">
                Study Case
              </a> */}
              <div className="mb-sm-30" />
            </div>
            <div className="col-lg-7 wow fadeInLeft" data-wow-delay=".5s">
              <img src={dddHome} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <OurServices />
      <PricingTable/>
      <section
    id="section-text"
    className="home-no-top"
  >
    <div className="container">
      <div className="row align-items-center">
        <div
          className="col-lg-6 d-none d-lg-block d-xl-block text-center wow fadeInRight"
          data-wow-delay="0s"
        >
          <img src={home2} className="img-fluid" alt=""/>
        </div>
        <div
          className="col-lg-5 offset-md-1 wow fadeInLeft"
          data-wow-delay="0s"
        >
          <h2>Bluetec prepare startups for accelerators</h2>
          <p className="lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. .
          </p>
          <div className="spacer-half" />
          <a className="btn-custom" href="#">
            Get Started
          </a>
        </div>
      </div>
    </div>
  </section>
      <CallSection/>
    </div>
  );
}
