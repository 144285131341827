import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function SubscriptionCard({ item, color }) {
    const {t} = useTranslation();
    const {isAuth} = useAuth();

    const handlePreferredPlan = (id) => {
       localStorage.setItem("preferred_plan_id", JSON.stringify(id));
    }
  return (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <div className="pricing-s1 mb30">
        <div className="top">
          <h2>{item.name}</h2>
          <p className="plan-tagline">{item.duration}</p>
        </div>
        <div className={`mid text-light ${color}`}>
          <p className="price">
            <span className="m opt-1">{item.price}</span>
            <span className="currency"> лв.</span>
          </p>
        </div>
        <div className="bottom">
          <ul>
            <li>
              <i className="fa fa-check" />
              1x Processing Power
            </li>
            <li>
              <i className="fa fa-check" />1 Website
            </li>
            <li>
              <i className="fa fa-check" />
              1GB Disk Space
            </li>
            <li>
              <i className="fa fa-check" />
              Easy Website Builder
            </li>
            <li>
              <i className="fa fa-check" />
              99.9% Uptime Guarantee
            </li>
            <li>
              <i className="fa fa-check" />
              24/7 Customer Support
            </li>
          </ul>
        </div>
        <div className="action">
          <Link
            to={`${isAuth ? "/subscribe" : "/login"}`}
            className="btn-custom"
            onClick={() => handlePreferredPlan(item.id)}
          >
            {t("Buy Now")}
          </Link>
        </div>
      </div>
    </div>
  );
}
