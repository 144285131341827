import SubscriptionWizard from "../../components/Subscription/Wizard";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function Subscription() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("Subscribe")}</title>
        <meta
          name="description"
          content={t(
            "Sign up for our subscription! Access exclusive content and stay updated with the latest news and offers. Subscribe now!"
          )}
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="no-bottom">
        <section id="subheader">
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col-md-12 text-center">
                      <h1>{t("Subscribe")}</h1>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SubscriptionWizard />
      </div>
    </>
  );
}
