import React, { createContext, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ENTranslations from "./locales/en.json";
import BGTranslations from "./locales/bg.json";

import { HelmetProvider } from "react-helmet-async";
import LanguageContext from "./contexts/LanguageContext";
import { AuthProvider } from "./contexts/AuthContext";
const Root = () => {
  const [language, setLanguage] = useState("en");

  const resources = {
    en: {
      translation: ENTranslations,
    },
    bg: {
      translation: BGTranslations,
    },
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <AuthProvider>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </LanguageContext.Provider>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Root />);
