
//const baseUrl = "http://dddadmin.test";
// const baseUrl = "https://admin.dddsoft.eu";

const baseUrl = process.env.REACT_APP_API_URL;

export const sendContact = async (contact) => {
  try {
    const response = await fetch(`${baseUrl}/api/contact-submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact),
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    }
  } catch (err) {
    throw new Error({ message: err.message });
  }
};

export const getPages = async () => {
  try {
    const response = await fetch(`${baseUrl}/api/pages`);
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};

export const getSinglePage = async (slug) => {
  try {
    const response = await fetch(`${baseUrl}/api/pages/${slug}`);
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error({ message: error.message });
  }
};

