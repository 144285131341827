export default function checkProjectStatus(project) {
  const now = new Date();
  const activeUntilDate = new Date(project.active_until);

  if (activeUntilDate > now) {
    const timeDifference = activeUntilDate - now;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (days > 30) {
      const months = Math.floor(days / 30);
      return {
        active: true,
        remainingTime: months,
        unit: "months",
        status: "active",
      };
    } else if (days > 0) {
      return {
        active: true,
        remainingTime: days,
        unit: "day",
        status: "active",
      };
    } else if (hours > 0) {
      return {
        active: true,
        remainingTime: hours,
        unit: "hour",
        status: "active",
      };
    } else {
      return {
        active: true,
        remainingTime: minutes,
        unit: "minute",
        status: "active",
      };
    }
  } else {
    return { active: false, remainingTime: 0, unit: "", status: "inactive" };
  }
}
 export function formatDate (value) {
   const date = new Date(value);
   const day = date.getDate().toString().padStart(2, "0");
   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
   const year = date.getFullYear();

   return `${day}.${month}.${year}`;
 };