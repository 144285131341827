import { useState } from "react";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as authServices from "../../services/AuthService";
import { useAuth } from "../../contexts/AuthContext";
import BtnLoader from "../../components/Utils/BtnLoader";
import { Helmet } from "react-helmet-async";

export default function Login() {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {login,token} = useAuth();
   const [userData, setUserData] = useState({
    email: "",
    password: "",
   });
   const [errors, setErrors] = useState({});
   const handleChange = (e) => {
    setUserData(state=>({
        ...state,
        [e.target.name]: e.target.value
    }));
   };
   const validate = (e) => {
     if (e.target.name === "email") {
       setErrors((state) => ({
         ...state,
         [e.target.name]: !userData.email.match(
           /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
         ),
       }));
     } 
   };
 
   const submit = (e) =>{
      e.preventDefault();
      if (errors.email) {
        return;
      }
      setLoading(true);
      authServices.login(userData)
      .then((result) => {
        if (result.success) {
          login(result.user,result.token);
          toast.success(result.message);
          navigate("/");
        }else{
          toast.error(result.message);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
   }
  return (
    <>
      <Helmet>
        <title>{t("Login to your account")}</title>
        <meta
          name="description"
          content={t(
            "Login to access your account! Enjoy personalized services and exclusive features. Your convenience, our priority."
          )}
          data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="no-bottom">
        <section id="subheader">
          <div className="center-y relative text-center" data-scroll-speed={4}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <form
                    action="blank.php"
                    className="row"
                    id="form_subscribe"
                    method="post"
                    name="myForm"
                  >
                    <div className="col-md-12 text-center">
                      <h1>{t("Login")}</h1>
                    </div>
                    <div className="clearfix" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="no-top"
          data-bgimage="url(images/background/3.png) top"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <form
                  name="contactForm"
                  id="contact_form"
                  className="form-border"
                  method="post"
                  action="blank.php"
                  onSubmit={submit}
                >
                  <h3>{t("Login to your account")}</h3>
                  <div className="field-set">
                    <label>{t("Email")}</label>
                    <input
                      type="text"
                      name="email"
                      className={`form-control ${errors.email && "invalid"}`}
                      placeholder=""
                      onChange={handleChange}
                      onBlur={validate}
                    />
                    {errors.email && (
                      <span className="error">
                        {t("Invalid email address")}
                      </span>
                    )}
                  </div>
                  <div className="field-set">
                    <label>{t("Password")}</label>
                    <input
                      type="password"
                      name="password"
                      className={`form-control ${errors.password && "invalid"}`}
                      placeholder=""
                      onChange={handleChange}
                    />
                  </div>
                  <div id="submit" className="pull-right">
                    <button className=" btn-custom color-2" disabled={loading}>
                      {loading ? <BtnLoader /> : t("Login")}
                    </button>
                    <Link to="/register" className=" btn-primary">
                      {t("Sing up?")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
