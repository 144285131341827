import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Step3({ subscriptionData, packages, main_domain }) {
  const { t } = useTranslation();
  const selectedPackage = packages.find(
    (el) => el.id == subscriptionData.subscription_type_id
  );
  return (
    <div className="c-selection c-overview selection-gap row justify-content-center pt-0 p-4 cards-wrapper col-md-9 m-w-100">
      <div className="expand-custom" style={{ backgroundSize: "cover" }}>
        <div className="ec-header" style={{ backgroundSize: "cover" }}>
          <div className="c2" style={{ backgroundSize: "cover" }}>
            <h4>{t("Order Summary")}</h4>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi.
            </p>
          </div>
        </div>
        <div
          className="details"
          style={{ backgroundSize: "cover", display: "block" }}
        >
          <div className="row" style={{ backgroundSize: "cover" }}>
            <div className="col-md-6" style={{ backgroundSize: "cover" }}>
              <div className="box-custom" style={{ backgroundSize: "cover" }}>
                <h4>{t("Subscription information")}</h4>
                <ul className="list s1">
                  <li>
                    <span className="description_title">{t("Package")}</span>
                    <i className="fa-solid fa-hand-point-right"></i>
                    <span className="description_value">
                      {selectedPackage.name}
                    </span>
                  </li>
                  <li>
                    <span className="description_title">{t("Duration")}</span>
                    <i className="fa-solid fa-hand-point-right"></i>
                    <span className="description_value">
                      {selectedPackage.duration}
                    </span>
                  </li>
                  <li>
                    <span className="description_title">{t("Price")}</span>
                    <i className="fa-solid fa-hand-point-right"></i>
                    <span className="description_value">
                      {selectedPackage.price} лв.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6" style={{ backgroundSize: "cover" }}>
              <div className="box-custom" style={{ backgroundSize: "cover" }}>
                <h4>{t("Billing information")}</h4>
                <ul className="list s1">
                  <li>
                    <span className="description_title">{t("User")}</span>
                    <i className="fa-solid fa-hand-point-right"></i>
                    <span className="description_value">
                      {subscriptionData.project_email}
                    </span>
                  </li>
                  <li>
                    <span className="description_title">
                      {t("Project Name")}
                    </span>
                    <i className="fa-solid fa-hand-point-right"></i>
                    <span className="description_value">
                      {subscriptionData.project_name}
                    </span>
                  </li>
                  <li>
                    <span className="description_title">{t("Domain")}</span>
                    <i className="fa-solid fa-hand-point-right"></i>
                    <span className="description_value">
                      {`https://${subscriptionData.domain_name}.${main_domain}.eu`}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
