import React, { useState, useEffect } from "react";
import "./PaymentsInfo.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import LanguageContext from "../../contexts/LanguageContext";
import checkProjectStatus, { formatDate } from "../../utils/functions";
export default function Subscriptions({ projects }) {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [headers, setHeaders] = useState({
    project_name: t("Project Name"),
    duration: t("Duration"),
    domain: t("Domain"),
    active_until: t("Active until"),
    start_date: t("Start Date"),
    project_status: t("Status"),
  });
  useEffect(() => {
    // Update the headers when the language changes
    setHeaders({
      project_name: t("Project Name"),
      duration: t("Duration"),
      domain: t("Domain"),
      active_until: t("Active until"),
      start_date: t("Start Date"),
      project_status: t("Status"),
    });
  }, [t, language]);

  const statusBodyTemplate = (project) => {
    const data = getSeverity(project);
    return <Tag value={data.status} severity={data.severity}></Tag>;
  };
  const domainBodyTemplate = (project) => {
    const domain = project.domain_name
      ? `https://${project.domain_name}.dddsoft.eu`
      : null;
    return domain ? (
      <a
        href={domain}
        target="_blank"
        rel="noopener noreferrer"
        className="font-bold"
      >
        {domain}
      </a>
    ) : (
      "N/A"
    );
  };
  const getSeverity = (project) => {
    const projectStatus = checkProjectStatus(project);
    if (projectStatus.active) {
      return {
        status: t("Active"),
        severity: "success",
      };
    } else {
      return {
        status: t("Inactive"),
        severity: "danger",
      };
    }
  };
  const formatDateString = (project) => {
    return formatDate(project.created_at);
  };
  const activeUntilBodyTemplate = (project) => {
    const date = formatDate(project.active_until);
    const projectStatus = checkProjectStatus(project);
    return (
      <div className="d-flex flex-column">
        <span>{date}</span>
        <span>
          {`${t("Remaining")} ${projectStatus.remainingTime} ${t(
            projectStatus.unit
          )}`}
        </span>
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">{t("Payments")}</span>
    </div>
  );
  const footer = `${t("In total there are ")} ${
    projects ? projects.length : 0
  } ${t("subscriptions.")}`;

  return (
    <>
      {projects.length === 0 ? (
        <div className="no-data">{t("No payments found.")}</div>
      ) : (
        <div className="payment-card">
          <DataTable
            value={projects}
            header={header}
            footer={footer}
            tableStyle={{ minWidth: "60rem" }}
            paginator
            rows={10}
          >
            <Column
              field="order_data.subscription_type_name"
              header={headers.start_date}
              body={formatDateString}
            ></Column>
            <Column field="name" header={headers.project_name}></Column>
            <Column
              field="order_data.client_domain"
              header={headers.domain}
              body={domainBodyTemplate}
            ></Column>
            <Column
              header={headers.active_until}
              body={activeUntilBodyTemplate}
            ></Column>
            <Column
              header={headers.project_status}
              body={statusBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}
