import "./Wizard.css";
import { Wizard } from "react-use-wizard";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import WizardHeader from "./subComponents/WizardHeader";
import WizardFooter from "./subComponents/WizardFooter";
import { useEffect, useState } from "react";
import Loader from "../Utils/Loader";

import * as subscriptionService from "../../services/SubscriptionService";
import Step3 from "./Steps/Step3";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
export default function SubscriptionWizard() {
  const {userData} = useAuth();
  const { t } = useTranslation();
  const preferred_plan_id = localStorage.getItem("preferred_plan_id");
  const main_domain = process.env.REACT_APP_MAIN_DOMAIN;

  const steps = [
    { name: t("Select Package") },
    { name: t("Billing Information") },
    { name: t("Overview") },
  ];
  const [subscriptionData, setSubscriptionData] = useState({
    subscription_type_id: preferred_plan_id ? preferred_plan_id : null,
    client_id: userData?.id,
  });
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
 const [errors, setErrors] = useState({});
  useEffect(() => {
    setLoading(true);
    subscriptionService
      .getSubscriptionPackages()
      .then((result) => {
        setPackages(result.packages);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loader />;
  }
  const handleSubscriptionData = (name,value) => {
    setSubscriptionData((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const updateErrors = (stepErrors) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...stepErrors,
    }));
  };
  return (
    <div className="wizard_wrapper">
      <Wizard
        header={<WizardHeader steps={steps} />}
        footer={
          <WizardFooter
            subscriptionData={subscriptionData}
            errors={errors}
            updateErrors={updateErrors}
          />
        }
      >
        <Step1
          packages={packages}
          handleSubscriptionData={handleSubscriptionData}
        />
        <Step2
          handleSubscriptionData={handleSubscriptionData}
          updateErrors={updateErrors}
          subscriptionData={subscriptionData}
          main_domain={main_domain}
        />
        <Step3
          subscriptionData={subscriptionData}
          packages={packages}
          main_domain={main_domain}
        />
      </Wizard>
    </div>
  );
}
