import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { toast } from "react-toastify";
import * as subscriptionService from "../../../services/SubscriptionService";

export default function WizardFooter({ subscriptionData, errors, updateErrors }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { previousStep, nextStep, isFirstStep, isLastStep, activeStep } =
    useWizard();
    const StepErrors =
    activeStep == 0 ? false : Object.values(errors).some((error) => error);
    // Determine if the "Next" button should be disabled
    const isNextDisabled = !subscriptionData?.subscription_type_id || StepErrors;
  useEffect(() => {
    if (activeStep == 0) {
      // Set errors to an empty object
      updateErrors({});
    }
  }, [activeStep]);

  const submit = () => {
    setLoading(true);
    subscriptionService.createSubscription(subscriptionData)
    .then((result)=>{
      if (result.success) {
        toast.success(result.message);
         window.location = result.session_url;
      }else{
        toast.error(result.message);
      }
    })
    .catch(err=>{
       toast.error(err.message);
    })
    .finally(()=>{
      setLoading(false);
    });
  };
  return (
    <div className="row justify-content-center pt-0 p-4" id="WizardFooter">
      <div className="wizard_footer">
        {!isFirstStep && (
          <button onClick={previousStep} className="wizard_btn">
            {t("Previous")}
          </button>
        )}
        {!isLastStep ? (
          <button
            onClick={nextStep}
            className="wizard_btn"
            disabled={isNextDisabled}
          >
            {t("Next")}
          </button>
        ) : (
          <button
            onClick={submit}
            className="wizard_btn"
            disabled={isNextDisabled}
          >
            {t("Payment")}
          </button>
        )}
      </div>
    </div>
  );
}
