import "./OurServices.css";
import servicesBg from "../../assets/servicesBg.png";
import { useTranslation } from "react-i18next";

export default function OurServices() {
  const {t} = useTranslation();
    return (
      <section-services
        id="section-highlight"
        className="no-top"
        data-bgimage="url(images/background/3b.png) top"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="text-center">
                <h2>
                  <span className="uptitle id-color">{t("Our Services")}</span>
                  {t("How does it work?")}
                </h2>
                <div className="spacer-20" />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-6 mb30 wow fadeInRight"
              data-wow-delay="0s"
            >
              <div className="f-box f-icon-left f-icon-rounded">
                <i className="fa fa-laptop bg-color-2 text-light" />
                <div className="fb-text">
                  <h4>Website Design</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mb30 wow fadeInRight"
              data-wow-delay=".25s"
            >
              <div className="f-box f-icon-left f-icon-rounded">
                <i className="fa fa-paper-plane-o bg-color-3 text-light" />
                <div className="fb-text">
                  <h4>Email Marketing</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mb30 wow fadeInRight"
              data-wow-delay=".5s"
            >
              <div className="f-box f-icon-left f-icon-rounded">
                <i className="fa fa-line-chart bg-color text-light" />
                <div className="fb-text">
                  <h4>Digital Analytics</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mb30 wow fadeInRight"
              data-wow-delay="0s"
            >
              <div className="f-box f-icon-left f-icon-rounded">
                <i className="fa fa-object-group bg-color-2 text-light" />
                <div className="fb-text">
                  <h4>Visual Editor</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mb30 wow fadeInRight"
              data-wow-delay=".25s"
            >
              <div className="f-box f-icon-left f-icon-rounded">
                <i className="fa fa-hdd-o bg-color-3 text-light" />
                <div className="fb-text">
                  <h4>Backup &amp; Security</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 mb30 wow fadeInRight"
              data-wow-delay=".5s"
            >
              <div className="f-box f-icon-left f-icon-rounded">
                <i className="fa fa-comments-o bg-color text-light" />
                <div className="fb-text">
                  <h4>IT Consultancy</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section-services>
    );
}